const UserActionTypes = {
  SET_CURRENT_USER: "SET_CURRENT_USER",
  SET_CURRENT_USER_NULL: "SET_CURRENT_USER_NULL",
  SET_USERS: "SET_USERS",
  SET_FAVORITE_STATION: "SET_FAVORITE_STATION",
  REMOVE_ONE_STATION: "REMOVE_ONE_STATION",
  ADD_ONE_STATION: "ADD_ONE_STATION",
  ADD_TO_FAVORITE: "ADD_TO_FAVORITE",
  SET_OPEN: "SET_OPEN",
};

export default UserActionTypes;
